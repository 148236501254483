import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PageInfo from "components/PageInfo";

const ThankYou = ({ location, data: { contact } }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Podziękowanie",
        description: "",
      }}
      contact_data={contact?.acfContact}
    >
      <PageInfo
        title="Wiadomość wysłana"
        text="Dziękujemy za skontaktowanie się z nami. Nasi pracownicy odpowiedzą najszybciej jak to możliwe."
        button={{
          label: "Strona główna",
          href: "/",
        }}
      />
    </Layout>
  );
};

export default ThankYou;

export const query = graphql`
  query {
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
